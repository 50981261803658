import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { IGroupTags } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { tap, switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class GroupTagsService extends HttpService {
  userService: UserService = inject(UserService);
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);

  getGroupTags(trackerTypeId: number): Observable<IGroupTags[]> {
    return from(this.indexedDb.getMaxId('group_tags')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<IGroupTags[]>(
          'group-tags/get-group-tags',
          false,
          { trackerTypeId, maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.expense,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((groupTags: IGroupTags[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            groupTags = structuredClone(
              groupTags.filter((i) => i !== undefined),
            );

            groupTags.forEach((groupTag) => {
              if (groupTag.tagsArray) return;
              groupTag.tagsArray = groupTag.tags?.split('|');
            });

            this.indexedDb.upsertBulk('group_tags', groupTags);
            return groupTags;
          }),
        );
      }),
    );
  }

  createGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/create-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }

  updateGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/update-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }

  deleteGroupTags(formData: IGroupTags) {
    return this.post<IGroupTags, IGroupTags>(
      'group-tags/delete-group-tags',
      formData,
    ).pipe(
      tap((groupTags: IGroupTags) => {
        this.indexedDb.upsertBulk('group_tags', [groupTags]);
      }),
    );
  }
}
