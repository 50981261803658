import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { IBudgetDetail } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class BudgetService extends HttpService {
  userService: UserService = inject(UserService);
  public getBudgetDetail(): Observable<IBudgetDetail[]> {
    return from(this.indexedDb.getMaxId('budget_detail')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<IBudgetDetail[]>(
          'budget/get-budget-details',
          false,
          { maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.budgetDetail,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((budgetDetail: IBudgetDetail[]) => {
            // *********** storing in indexed db ************
            // @ts-ignore
            budgetDetail = structuredClone(
              budgetDetail.filter((i) => i !== undefined),
            );
            this.indexedDb.upsertBulk('budget_detail', budgetDetail);
            return budgetDetail;
          }),
        );
      }),
    );
  }
}
