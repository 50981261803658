import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iReoccurringTracks } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { tap, switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class ReoccurringExpensesOrIncomeService extends HttpService {
  userService: UserService = inject(UserService);
  getExpensesOrIncome(): Observable<iReoccurringTracks[]> {
    let isFromDB = false;
    let maxId: number = 0;
    return from(this.indexedDb.getMaxId('reoccurring_tracks')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<iReoccurringTracks[]>(
          'reoccurring-tags/get-reoccurring-tags',
          false,
          { maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.reoccurringTrack,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((reoccurringTracks: iReoccurringTracks[]) => {
            // *********** storing in indexed db ************

            // @ts-ignore
            reoccurringTracks = structuredClone(
              reoccurringTracks.filter((i) => i !== undefined),
            ).map((i) => {
              if (i.tagsArray === undefined && i.tags) {
                // @ts-ignore
                i.tagsArray = i.tags.split('|');
              }
              return i;
            });

            this.indexedDb.upsertBulk('reoccurring_tracks', reoccurringTracks);

            return reoccurringTracks;
          }),
        );
      }),
    );
  }

  addExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/add-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }

  updateExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/update-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }

  deleteExpensesOrIncome(
    track: iReoccurringTracks,
  ): Observable<iReoccurringTracks> {
    return this.post<iReoccurringTracks, iReoccurringTracks>(
      'reoccurring-tags/delete-reoccurring-tags',
      track,
    ).pipe(
      tap((reoccurringTracks: iReoccurringTracks) => {
        this.indexedDb.upsertBulk('reoccurring_tracks', [reoccurringTracks]);
      }),
    );
  }
}
