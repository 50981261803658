import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { IIconUpload } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { tap, switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class IconUploadService extends HttpService {
  userService: UserService = inject(UserService);
  getIcons(): Observable<IIconUpload[]> {
    return from(this.indexedDb.getMaxId('icon_uploads')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<IIconUpload[]>(
          'icon-upload/get-icons',
          false,
          { trackerTypeId: 1, maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.uploadIconForTags,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((iconUpload: IIconUpload[]) => {
            // @ts-ignore
            iconUpload = structuredClone(
              iconUpload.filter((i) => i !== undefined),
            );

            this.indexedDb.upsertBulk('icon_uploads', iconUpload);
            return iconUpload;
          }),
        );
      }),
    );
  }
  createIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/add-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
  updateIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/update-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
  deleteIcon(formData: IIconUpload) {
    return this.post<IIconUpload, IIconUpload>(
      'icon-upload/delete-icon',
      formData,
    ).pipe(
      tap((iconUpload: IIconUpload) => {
        if (iconUpload) {
          this.indexedDb.upsertBulk('icon_uploads', [iconUpload]);
        }
      }),
    );
  }
}
