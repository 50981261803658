import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iTrack, MaxIdPerUser } from '../common/interface';
import { Expense, ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';
import { TagsCacheService } from './tags-cache.service';

@Injectable({
  providedIn: 'root',
})
export class TrackService extends HttpService {
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);
  userService: UserService = inject(UserService);
  tagsCacheService: TagsCacheService = inject(TagsCacheService);

  getTracks(trackerTypeId: number): Observable<iTrack[]> {
    return from(this.indexedDb.getMaxId('tracker')).pipe(
      switchMap((maxIdPerUser: MaxIdPerUser[]) => {
        // get the current id for the user
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<iTrack[]>(
          'track/get-all-track-by-tracker-type',
          false,
          { trackerTypeId, maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.expense,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((trackers) => {
            // @ts-ignore
            const tracks = structuredClone(
              trackers.filter((i) => i !== undefined),
            );

            if (tracks.length > 0) {
              this.indexedDb.upsertBulk('tracker', tracks);
              this.expenseEngine.setExpenses(tracks as Expense[]);
            }

            return tracks;
          }),
        );
      }),
    );
  }

  addExpenses(expense: iTrack): Observable<iTrack> {
    return this.post<iTrack, iTrack>('track/add-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');

        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  updateExpense(expense: iTrack): Observable<iTrack> {
    // clear tag cache
    this.tagsCacheService.clearCache();
    return this.post<iTrack, iTrack>('track/update-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  deleteExpense(expense: iTrack): Observable<iTrack> {
    // clear tag cache
    this.tagsCacheService.clearCache();
    return this.post<iTrack, iTrack>('track/delete-track', expense).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags!.split('|');
        this.indexedDb.upsertBulk('tracker', [data]);
        this.expenseEngine.setExpenses([data as Expense]);
        return data;
      }),
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
