import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iGroup } from '../common/interface';
import { ExpenseEngineService } from './expense-engine.service';
import { HttpService } from './http.service';
import { Observable, from } from 'rxjs';
import { switchMap, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService extends HttpService {
  expenseEngine: ExpenseEngineService = inject(ExpenseEngineService);
  userService: UserService = inject(UserService);

  getGroups(trackerTypeId: Trackers): Observable<iGroup[]> {
    return from(this.indexedDb.getMaxId('groups')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<iGroup[]>(
          'group/get-groups',
          false,
          { trackerTypeId, maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.groups,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((groups) => {
            // *********** storing in indexed db ************
            // @ts-ignore
            groups = structuredClone(groups.filter((i) => i !== undefined));

            this.indexedDb.upsertBulk('groups', groups);
            return groups;
          }),
        );
      }),
    );
  }
  createGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/add-group', formData);
  }
  updateGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/update-group', formData);
  }
  deleteGroup(formData: iGroup) {
    return this.post<iGroup, iGroup>('group/delete-group', formData);
  }
}
