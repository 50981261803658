import { inject, Injectable } from '@angular/core';
import { Trackers } from '../common/enum';
import { iIncome } from '../common/interface';
import { HttpService } from './http.service';
import { Observable, combineLatest, from } from 'rxjs';
import { tap, switchMap, startWith, filter, map } from 'rxjs/operators';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class IncomeService extends HttpService {
  userService: UserService = inject(UserService);

  getIncome(): Observable<iIncome[]> {
    return from(this.indexedDb.getMaxId('income')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<iIncome[]>(
          'income/get-income',
          false,
          { maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.income,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((income: iIncome[]) => {
            // @ts-ignore
            income = structuredClone(income.filter((i) => i !== undefined)).map(
              (i) => {
                if (i.tagsArray === undefined && i.tags) {
                  // @ts-ignore
                  i.tagsArray = i.tags.split('|');
                }
                return i;
              },
            );

            this.indexedDb.upsertBulk('income', income);
            return income;
          }),
        );
      }),
    );
  }

  addIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/add-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  updateIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/update-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  deleteIncome(income: iIncome): Observable<iIncome> {
    return this.post<iIncome, iIncome>('income/delete-income', income).pipe(
      map((data) => {
        data.extraInfo = this.getExtraInfoObj(data.extraInfo);
        data.tagsArray = data.tags.split('|');
        this.indexedDb.upsertBulk('income', [data]);
        return data;
      }),
    );
  }

  getExtraInfoObj(extraInfo: string) {
    try {
      return JSON.parse(extraInfo);
    } catch (error) {
      return extraInfo;
    }
  }
}
