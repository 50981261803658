import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Trackers } from '../common/enum';
import { iTags, iIcon, iIgnoreTags } from '../common/interface';
import { HttpService } from './http.service';
import { UserService } from 'src/app/store/settings/user/service/user.service';

@Injectable({
  providedIn: 'root',
})
export class TagsService extends HttpService {
  userService: UserService = inject(UserService);
  tagIcons: iIcon = {};

  createIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/create-ignore-tag',
      tag,
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      }),
    );
  }

  updateIgnoreTag(tag: iIgnoreTags): Observable<iIgnoreTags> {
    return this.post<iIgnoreTags, iIgnoreTags>(
      'tags/update-ignore-tag',
      tag,
    ).pipe(
      tap((ignoreTags: iIgnoreTags) => {
        if (ignoreTags) {
          this.indexedDb.upsertBulk('ignore_tags', [ignoreTags]);
        }
      }),
    );
  }

  addTagForIcon(tag: iTags): Observable<iTags> {
    return this.post<iTags, iTags>('tags/add-tag-for-icon', tag).pipe(
      map((tag) => {
        this.tagIcons[tag.name] = tag.iconUrl;
        return tag;
      }),
    );
  }

  getIgnoreTags(): Observable<iIgnoreTags[]> {
    return from(this.indexedDb.getMaxId('ignore_tags')).pipe(
      switchMap((maxIdPerUser) => {
        // if maxIdPerUser has 1 user and it matches with userServicer's userId
        // then get the maxId from maxIdPerUser
        const currentMaxExpenseId =
          maxIdPerUser.find((user) => user.userId === +this.userService.userId)
            ?.maxId || 0;

        return this.get<iIgnoreTags[]>(
          'tags/get-ignore-tags',
          false,
          { maxIdPerUser: JSON.stringify(maxIdPerUser) },
          Trackers.ignoreTags,
          currentMaxExpenseId === 0,
        ).pipe(
          filter((i) => i.length > 0),
          map((ignoreTags: iIgnoreTags[]) => {
            // @ts-ignore
            ignoreTags = structuredClone(
              ignoreTags.filter((i) => i !== undefined),
            );

            this.indexedDb.upsertBulk('ignore_tags', ignoreTags);
            return ignoreTags;
          }),
        );
      }),
    );
  }
}
